import InputField from "../../formFields/InputField";
import MaskField from "../../formFields/MaskField";
import GenderSelectField from "../../formFields/GenderSelectField";
import DateField from "../../formFields/DateField";
import NumberField from "../../formFields/NumberField";
import ProfilePictureField from "../../formFields/ProfilePictureField";

export const validationRules = {
  firstName: {
    required: "First name is required.",
    min: {
      value: 3,
      message: "First name must be at least 3 characters long",
    },
    max: {
      value: 20,
      message: "First name must be 20 characters or less",
    },
  },
  lastName: {
    required: "Last name is required.",
    min: {
      value: 3,
      message: "First name must be at least 3 characters long",
    },
    max: {
      value: 30,
      message: "First name must be 30 characters or less",
    },
  },
  email: {
    // required: "Email is required.",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Invalid email address. E.g. example@email.com",
    },
  },
  phoneNumber: {
    required: "Phone number is required.",
  },
  dob: {
    required: "Date of birth is required",
  },
  gender: {
    required: "Gender is required",
  },
  waist: {
    required: "Waist is required",
    min: {
      value: 10,
      message: "Waist must be at least 10 Cm",
    },
    max: {
      value: 300,
      message: "Waist must be no more than 300 Cm",
    },
  },
  height: {
    required: "Height is required",
    min: {
      value: 4,
      message: "Height must be at least 4 ft",
    },
    max: {
      value: 7,
      message: "Height must be no more than 7 ft",
    },
  },
  weight: {
    required: "Weight is required",
    min: {
      value: 1,
      message: "Weight must be at least 1",
    },
    max: {
      value: 300,
      message: "Weight must be no more than 300",
    },
  },
};

// we can also do something like this
const fieldConfigurations = [
  {
    component: ProfilePictureField,
    name: "profile_picture",
    label: "Profile Picture",
    placeholder: "",
    rules: validationRules.profilePicture,
    className: "col-span-2 flex justify-center",
    additionalProps: {},
  },
  {
    component: InputField,
    name: "first_name",
    label: "First Name",
    placeholder: "Enter your first name",
    rules: validationRules.firstName,
    className: "md:col-span-1 col-span-2",
  },
  {
    component: InputField,
    name: "last_name",
    label: "Last Name",
    placeholder: "Enter your last name",
    rules: validationRules.lastName,
    className: "md:col-span-1 col-span-2",
  },
  // ... more fields
];

export { fieldConfigurations };
