import moment from "moment";
import Papa from "papaparse";
import { toast } from "react-toastify";

export const preprocessCSV = (csvData) => {
  const allLines = csvData.split(/\r\n|\n|\r/);
  const headerIndex = allLines.findIndex((line) =>
    line.includes("Device Timestamp")
  );
  return allLines.slice(headerIndex).join("\n");
};

export const parseCSV = (content, onSuccess, onError) => {
  const requiredColumns = ["Historic Glucose mg/dL", "Device Timestamp"];
  const processedCSV = preprocessCSV(content);
  Papa.parse(processedCSV, {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      const rows = results.data;
      if (rows.length > 0) {
        const actualColumns = Object.keys(rows[0]);
        const isValid = requiredColumns.every((col) =>
          actualColumns.includes(col)
        );
        if (isValid) {
          const transformedData = rows
            .map((row) => {
              const glucoseValue = Number(
                row["Historic Glucose mg/dL"]
                // || row["Scan Glucose mg/dL"]
              );
              if (glucoseValue !== 0) {
                const timestampInMilliseconds = moment(
                  row["Device Timestamp"],
                  "DD-MM-YYYY hh:mm A"
                ).valueOf();
                return {
                  timestamp: timestampInMilliseconds,
                  glucose: glucoseValue,
                };
              }
              return null;
            })
            .filter((row) => row !== null);
          onSuccess(transformedData);
        } else {
          toast.show("CSV is missing one or more required columns.");
        }
      } else {
        toast.error("CSV is empty.");
      }
    },
    error: (error) => {
      toast.error("Error parsing CSV: " + error.message);
    },
  });
};

export const parseTextFile = (content, onSuccess, onError) => {};
