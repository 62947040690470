import { Accordion, AccordionTab } from "primereact/accordion";
import React from "react";
import AutoCompleteField from "../../formFields/AutoCompleteField";
import GlycemicIndexField from "../../formFields/GlycemicIndexField";
import InputField from "../../formFields/InputField";
import FoodFormMacroNutrientsFields from "./FoodFormMacroNutrientsFields";
import FoodFormMeasuringFields from "./FoodFormMeasuringFields";
import FoodFormMicroNutrientsFields from "./FoodFormMicroNutrientsFields";
import FoodFormOptionalFields from "./FoodFormOptionalFields";
import { validationRules } from "./fieldConfigurations";

const FoodFormFields = ({ control, errors, data, categoryList }) => {
  return (
    <div className="gap-5 grid">
      <InputField
        name="food_name"
        control={control}
        label="Food Name"
        placeholder="Enter Food name"
        rules={validationRules.food_name}
        errors={errors}
      />

      <InputField
        name="food_id"
        control={control}
        label="Food Id"
        placeholder="Enter Food id"
        rules={validationRules.food_id}
        errors={errors}
        keyfilter={/^[a-z_]*$/}
      />

      <AutoCompleteField
        name="primary_category"
        control={control}
        label="Category"
        placeholder="Enter Category"
        rules={validationRules.primary_category}
        errors={errors}
        options={categoryList}
      />

      <GlycemicIndexField
        control={control}
        rules={validationRules.gender}
        errors={errors}
      />

      <Accordion activeIndex={0}>
        <AccordionTab header="Measuring Unit and Quantity">
          <FoodFormMeasuringFields
            control={control}
            errors={errors}
            data={data}
          />
        </AccordionTab>
        <AccordionTab header="Macro Nutrients">
          <FoodFormMacroNutrientsFields
            control={control}
            errors={errors}
            data={data}
          />
        </AccordionTab>
        <AccordionTab header="Micro Nutrients">
          <FoodFormMicroNutrientsFields
            control={control}
            errors={errors}
            data={data}
          />
        </AccordionTab>
        <AccordionTab header="Tags">
          <FoodFormOptionalFields control={control} errors={errors} />
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default FoodFormFields;
