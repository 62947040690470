import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useDialog } from "../contexts/DialogContext";

const CalendarDialog = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { dialogStates, closeDialog } = useDialog();
  const { setSelectedPeriod, showTime = false } = dialogStates.calendar.props;

  const onChange = (e) => {
    setSelectedDate(e.value);

    if (e.value?.length === 2) {
      const [start, end] = e.value;

      if (start && end) {
        let startDateTime = moment(start).startOf("day").valueOf();
        let endDateTime = moment(end);

        if (start.toDateString() === end.toDateString()) {
          endDateTime = moment()
            .set({
              hour: end.getHours(),
              minute: end.getMinutes(),
              second: end.getSeconds(),
              millisecond: end.getMilliseconds(),
            })
            .valueOf();
        } else {
          endDateTime = endDateTime.endOf("day").valueOf();
        }

        setSelectedPeriod({
          start: startDateTime,
          end: endDateTime,
        });

        closeDialog("calendar");
      }
    }
  };

  return (
    <Dialog
      header="Header"
      showHeader={false}
      dismissableMask={true}
      visible={dialogStates.calendar.visible}
      className="w-[30vw]"
      contentClassName="!p-0 !rounded-lg"
      onHide={() => closeDialog("calendar")}
    >
      <Calendar
        value={selectedDate}
        hourFormat="12"
        selectionMode="range"
        showTime={showTime}
        showButtonBar
        inline
        hideOnDateTimeSelect
        className="w-full"
        todayButtonClassName="!py-2 !px-4 !text-primary"
        clearButtonClassName="!py-2 !px-4 !text-primary"
        maxDate={new Date()}
        onChange={(e) => onChange(e)}
      ></Calendar>
    </Dialog>
  );
};

export default CalendarDialog;
