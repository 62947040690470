import React from "react";
import { Controller } from "react-hook-form";
import { InputMask } from "primereact/inputmask";

const MaskField = ({
  name,
  control,
  label,
  unit,
  placeholder,
  rules,
  errors,
  mask,
  disabled,
  helpText = "",
}) => {
  return (
    <div className="field">
      <label htmlFor={name} className="block">
        {label}
        {unit && <span className="text-xs">({unit})</span>}
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <InputMask
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              className={`${
                fieldState.invalid ? "p-invalid" : ""
              } p-inputtext-sm w-full`}
              placeholder={placeholder}
              mask={mask}
              disabled={disabled}
            />
            {helpText?.length > 0 && (
              <small className="block text-xs">{helpText}</small>
            )}
            {errors[name] && (
              <small className="p-error block">{errors[name].message}</small>
            )}
          </>
        )}
      />
    </div>
  );
};

export default MaskField;
