import { createContext, useContext, useState } from "react";

const PrintViewContext = createContext();

export const PrintViewProvider = ({ children }) => {
  const [isPrintView, setIsPrintView] = useState(false);

  return (
    <PrintViewContext.Provider
      value={{
        isPrintView,
        setIsPrintView,
      }}
    >
      {children}
    </PrintViewContext.Provider>
  );
};

export const usePrintView = () => {
  return useContext(PrintViewContext);
};
