import React from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneNumberField = ({
  name,
  control,
  label,
  iconClass,
  placeholder,
  rules,
  errors,
  keyfilter,
  defaultValue = "",
  helpText = "",
}) => {
  return (
    <div className="field">
      <label htmlFor={name} className="block">
        {label}
      </label>
      <span className="w-full">
        {iconClass && <i className={iconClass} />}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <PhoneInput
                {...field}
                className={`${
                  fieldState.invalid ? "p-invalid" : ""
                } p-inputtext p-inputtext-sm p-component w-full`}
                placeholder={placeholder}
                defaultCountry="IN"
              />
            </>
          )}
        />
      </span>
      {helpText?.length > 0 && (
        <small className="block !text-[0.70rem] py-1">{helpText}</small>
      )}
      {errors[name] && (
        <small className="p-error block">{errors[name].message}</small>
      )}
    </div>
  );
};

export default PhoneNumberField;
