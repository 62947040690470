import { Image } from "primereact/image";
import React from "react";
import { Controller } from "react-hook-form";
import {
  FEMALE_PROFILE_PIC_PLACEHOLDER,
  MALE_PROFILE_PIC_PLACEHOLDER,
} from "../../utils/constants";
import { FileUpload } from "primereact/fileupload";

const ProfilePictureField = ({
  name,
  control,
  label,
  iconClass,
  placeholder,
  rules,
  errors,
  defaultValue = "",
  helpText = "",
  gender,
}) => {
  const chooseOptions = {
    label: "Choose",
    icon: "pi pi-plus",
    className: "p-button-sm !py-1.5",
  };
  const profilePicPlaceholder =
    gender === "female"
      ? FEMALE_PROFILE_PIC_PLACEHOLDER
      : MALE_PROFILE_PIC_PLACEHOLDER;

  return (
    <div className="field text-center">
      {/* <label htmlFor={name} className="block">
        {label}
      </label> */}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <>
            <Image
              src={field.value || profilePicPlaceholder}
              className="shadow !h-24 !w-24 !rounded-full overflow-hidden"
              preview={true}
            />
            {/* <FileUpload mode="basic" chooseOptions={chooseOptions}></FileUpload> */}
          </>
        )}
      />
    </div>
  );
};

export default ProfilePictureField;
