import { BASE_URL, FETCH_HEALTH_FACILITIES_LIST } from "../utils/constants";
import { useState } from "react";

const healthFacilityApiService = () => {
  const fetchHealthFacilities = async () => {
    const uri = BASE_URL + FETCH_HEALTH_FACILITIES_LIST;
    try {
      const response = await fetch(uri);
      if (response.ok) {
        const result = await response.json();
        return { success: true, data: result };
      } else {
        const error = await response.text();
        return { success: false, error };
      }
    } catch (error) {
      console.error("fetchHealthFacilities error:", error);
      return { success: false, error: error.message };
    }
  };

  return { fetchHealthFacilities };
};

export default healthFacilityApiService;
