import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const SidebarMenuItem = ({ icon, text, link, rightText = "" }) => {
  const location = useLocation();
  const isActive = location.pathname === link;

  const itemClassName = `flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
    isActive
      ? "text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-800"
      : "text-gray-900 dark:text-white"
  }`;

  return (
    <li>
      <Link to={link} className={itemClassName}>
        {icon}
        <span className="ml-3">{text}</span>
        {rightText && (
          <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">
            Pro
          </span>
        )}
      </Link>
    </li>
  );
};

SidebarMenuItem.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  rightText: PropTypes.string,
};

export default SidebarMenuItem;
