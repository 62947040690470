import React from "react";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";

const DropdownField = ({
  name,
  control,
  label,
  iconClass,
  placeholder,
  rules,
  errors,
  options,
  filter = false,
  defaultValue = "",
  helpText = "",
}) => {
  const formatOptions = (options) => {
    if (Array.isArray(options)) {
      return options.map((option) => ({
        name: option,
        value: option,
      }));
    }
    return options;
  };

  const processedOptions = formatOptions(options);

  return (
    <div className="field">
      <label htmlFor={name} className="block">
        {label}
      </label>
      <span className=" p-input-icon-right w-full">
        {iconClass && <i className={iconClass} />}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <Dropdown
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={processedOptions}
                placeholder={placeholder}
                filter={filter}
                optionLabel="name"
                className={`${
                  fieldState.invalid ? "p-invalid" : ""
                } p-inputtext-sm w-full`}
              />
            </>
          )}
        />
      </span>
      {helpText?.length > 0 && (
        <small className="block text-xs">{helpText}</small>
      )}
      {errors[name] && (
        <small className="p-error block">{errors[name].message}</small>
      )}
    </div>
  );
};

export default DropdownField;
