import { BRIDGE_BASE_URL, UPLOAD_CGM_DATA } from "../utils/constants";

export const uploadCGMData = async (data, userId) => {
  const endpoint = BRIDGE_BASE_URL + UPLOAD_CGM_DATA;
  const request_data = {
    sugar_data: data,
    patient_id: userId,
    user_id: userId,
    timezone: "Asia/Kolkata",
  };

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_data),
    });
    if (response.ok) {
      const result = await response.json();
      return { success: true, data: result };
    } else {
      const error = await response.text();
      return { success: false, error };
    }
    // return { success: true, data: {} };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
