import { useEffect, useState } from "react";
import { BASE_URL, FETCH_PATIENT_DATA } from "../utils/constants";

export const usePatientData = (patientId, isVisible) => {
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPatientData = async () => {
      if (isVisible && patientId) {
        setLoading(true);
        setError(null);
        try {
          const response = await fetch(
            `${BASE_URL + FETCH_PATIENT_DATA}?patient_id=${patientId}`
          );
          const data = await response.json();
          setPatientData(data);
        } catch (err) {
          setError(err.message);
          setPatientData(null);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPatientData();
  }, [patientId, isVisible]);

  return { patientData, loading, error };
};
