import React from "react";
import SidebarHeader from "./SidebarHeader";
import SidebarToggleBtn from "./SidebarToggleBtn";
import SidebarItems from "./SidebarItems";
import SidebarLayout from "./SidebarLayout";
import BodyLayout from "../BodyLayout";

const Sidebar = () => {
  return (
    <div>
      {/* <SidebarToggleBtn /> */}
      <SidebarLayout>
        <SidebarHeader />
        <SidebarItems />
      </SidebarLayout>
      <BodyLayout />
    </div>
  );
};

export default Sidebar;
