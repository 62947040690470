import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { AutoComplete } from "primereact/autocomplete";

const AutoCompleteField = ({
  name,
  control,
  label,
  iconClass,
  placeholder,
  rules,
  errors,
  options,
  forceSelection = false,
  defaultValue = "",
  helpText = "",
}) => {
  const formattedOptions = () => {
    if (Array.isArray(options)) {
      return options.map((option) => ({
        name: option,
        value: option,
      }));
    }
    return options;
  };

  const [filteredData, setFilteredData] = useState(formattedOptions());

  const searchData = (event) => {
    let query = event.query.toLowerCase();
    let filtered = formattedOptions().filter((option) =>
      option.name.toLowerCase().startsWith(query)
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    setFilteredData(formattedOptions());
  }, [options]);

  return (
    <div className="field">
      <label htmlFor={name} className="block">
        {label}
      </label>
      <span className=" p-input-icon-right w-full">
        {iconClass && <i className={iconClass} />}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <AutoComplete
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                suggestions={filteredData}
                completeMethod={searchData}
                forceSelection={forceSelection}
                dropdown
                placeholder={placeholder}
                field="name"
                aria-label={label}
                dropdownAriaLabel={placeholder}
                className="w-full"
                inputClassName={`${
                  fieldState.invalid ? "p-invalid" : ""
                } p-inputtext-sm w-full`}
              />
            </>
          )}
        />
      </span>
      {helpText?.length > 0 && (
        <small className="block text-xs">{helpText}</small>
      )}
      {errors[name] && (
        <small className="p-error block">{errors[name].message}</small>
      )}
    </div>
  );
};

export default AutoCompleteField;
