import React from "react";
import CalendarDialog from "./CalendarDialog";
import UploadCGMDataDialog from "./UploadCGMDataDialog";
import PatientFormDialog from "./dialogs/PatientFormDialog/PatientFormDialog";
import FoodFormDialog from "./dialogs/FoodForm/FoodFormDialog";

const CommonAncestorComponent = () => {
  return (
    <>
      {/* ... other parts of your application */}
      <UploadCGMDataDialog />
      <CalendarDialog />
      <PatientFormDialog />
      <FoodFormDialog />
      {/* Render more dialogs as needed */}
    </>
  );
};

export default CommonAncestorComponent;
