import { useState } from "react";
import { BASE_URL, FETCH_DETAILED_MEAL_STATS } from "../utils/constants";

const overviewApiService = ({ selectedPeriod, selectedFacilities }) => {
  const fetchMealStats = async () => {
    const params = new URLSearchParams({
      startDatetime: selectedPeriod?.start,
      endDatetime: selectedPeriod?.end,
      healthFacilityId: selectedFacilities,
    }).toString();
    const uri = `${BASE_URL}${FETCH_DETAILED_MEAL_STATS}?${params}`;
    try {
      const response = await fetch(uri);
      if (response.ok) {
        const result = await response.json();
        return { success: true, data: result };
      } else {
        const error = await response.text();
        return { success: false, error };
      }
    } catch (error) {
      console.error("fetchMealStats error:", error);
      return { success: false, error: error.message };
    }
  };

  return { fetchMealStats };
};

export default overviewApiService;
