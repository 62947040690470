export const LOGO_URL =
  "https://www.7sugar.com/assets/images/7-Sugar-Logo-transparent.png";

export const MALE_PROFILE_PIC_PLACEHOLDER =
  "https://cdn.create.vista.com/api/media/small/121232794/stock-vector-male-default-placeholder-avatar-profile-gray-picture-isolated-on-white-background-for-your-design-vector";

export const FEMALE_PROFILE_PIC_PLACEHOLDER =
  "https://cdn.stockmediaserver.com/smsimg35/pv/IsignstockContributors/ISS_18592_03646.jpg?token=nZJUOTVSRUHLxVL3Sa6RcepIx37J2IG5RVg-PNmqT_U&class=pv&smss=53&expires=4102358400";

export const BASE_URL = "https://api.7sugar.com/web/admin/";

export const BRIDGE_BASE_URL = "https://api.7sugar.com/web/bridge/";

export const FETCH_PATIENTS_LIST =
  "patient_list?user_id=e4f3c753d38d519cbe708cf482b0a2b7root";

export const FETCH_CAREGIVER_LIST =
  "get_caregiver_list?user_id=e4f3c753d38d519cbe708cf482b0a2b7root";

export const FETCH_HEALTH_FACILITIES_LIST = "healthfacilities_list";

export const FETCH_DETAILED_MEAL_STATS = "detailed-meal-stats";

export const FETCH_DIET_REPORT = "diet_report";

export const FETCH_SMBG_REPORT = "smbg_report";

export const FETCH_PATIENT_DATA = "get-patient-data";

export const UPDATE_PATIENT_DATA = "update-patient-profile";

export const UPLOAD_CGM_DATA = "uploadsugar";

export const FETCH_FOOD_ITEMS = "food-items";

export const ADD_FOOD_ITEM = "add-food-item";

export const UPDATE_FOOD_ITEM = "update-food-item";
