import React, { lazy, StrictMode, Suspense } from "react";
import ReactDom from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { DietReportProvider } from "./contexts/DietReportContext";

import "@fontsource/nunito/600.css";
import Cookies from "js-cookie";

import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/tailwind-light/theme.css";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Error from "./components/Error";
import Home from "./components/Home";
import LayoutWrapper from "./components/LayoutWrapper";
import Login from "./components/Login";
import Test from "./components/Test";

const PatientsDataTable = lazy(() => import("./components/PatientsDataTable"));

const PatientProfile = lazy(() => import("./components/PatientProfile"));

const CaregiversDataTable = lazy(() =>
  import("./components/CaregiversDataTable")
);

const FoodDataTable = lazy(() => import("./components/FoodDataTable"));

const DietReport = lazy(() => import("./components/reports/diet/DietReport"));

const SMBGReport = lazy(() => import("./components/reports/smbg/SMBGReport"));

const isLoggedIn = Cookies.get("token");

console.log("isLoggedIn: ", isLoggedIn);

PrimeReact.ripple = true;

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <StrictMode>
        <LayoutWrapper />
      </StrictMode>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/patients",
        element: (
          <Suspense>
            <PatientsDataTable />
          </Suspense>
        ),
      },
      {
        path: "patient/:patientId",
        element: (
          <Suspense>
            <PatientProfile />
          </Suspense>
        ),
      },
      {
        path: "patient/:patientId/diet-report",
        element: (
          <Suspense>
            <DietReportProvider>
              <DietReport />
            </DietReportProvider>
          </Suspense>
        ),
      },
      {
        path: "patient/:patientId/smbg-report",
        element: (
          <Suspense>
            <SMBGReport />
          </Suspense>
        ),
      },
      {
        path: "/caregivers/",
        element: (
          <Suspense>
            <CaregiversDataTable />
          </Suspense>
        ),
      },
      {
        path: "/food/",
        element: (
          <Suspense>
            <FoodDataTable />
          </Suspense>
        ),
      },
      {
        path: "/test",
        element: <Test />,
      },
    ],

    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const root = ReactDom.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
