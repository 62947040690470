import React from "react";
import NumberField from "../../formFields/NumberField";

const nutrients = [
  { name: "calcium", label: "Calcium" },
  { name: "iron", label: "Iron" },
  { name: "phosphorus", label: "Phosphorus" },
  { name: "potassium", label: "Potassium" },
  { name: "zinc", label: "Zinc" },
];

const FoodFormMicroNutrientsFields = ({ control, errors }) => {
  return (
    <div className="grid gap-5">
      {nutrients.map((nutrient) => (
        <NumberField
          key={nutrient.name}
          name={nutrient.name}
          control={control}
          label={nutrient.label}
          unit="in mg"
          placeholder={`Enter ${nutrient.label.toLowerCase()}`}
          errors={errors}
          mode="decimal"
          suffix=" mg"
          minFractionDigits={1}
          maxFractionDigits={2}
        />
      ))}
    </div>
  );
};

export default FoodFormMicroNutrientsFields;
