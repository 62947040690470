import React from "react";
import { useLocation } from "react-router-dom";
import UserMenuBtn from "./UserMenuBtn";
import SidebarToggleBtn from "./Sidebar/SidebarToggleBtn";

// ref: https://flowbite.com/docs/components/navbar/
const Navbar = () => {
  const location = useLocation();

  const dietReportRegexPattern =
    /\/patient\/(?<patientId>[A-Za-z0-9]+)\/diet-report\/?$/;
  const smbgReportRegexPattern =
    /\/patient\/(?<patientId>[A-Za-z0-9]+)\/smbg-report\/?$/;

  const dietReportMatch = location.pathname.match(dietReportRegexPattern);
  const smbgReportMatch = location.pathname.match(smbgReportRegexPattern);

  const routeToTitle = {
    "/patients": "Patients List",
    "/caregivers": "Caregivers List",
    "/health-facilities": "Health Facility List",
    "/packages": "Packages List",
  };

  let currentTitle = "Dashboard"; // Default title

  if (dietReportMatch) {
    currentTitle = "Diet Report";
  } else if (smbgReportMatch) {
    currentTitle = "SMBG Report";
  } else {
    currentTitle = routeToTitle[location.pathname] || currentTitle;
  }

  return (
    <div>
      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="flex flex-wrap justify-between items-center mx-auto  p-4">
          {/* max-w-screen-xl */}
          <div className="flex items-center">
            <SidebarToggleBtn />
            <h2 className="text-xl font-bold">{currentTitle}</h2>
          </div>
          <div className="flex items-center">
            {/* <a
              href="tel:5541251234"
              className="mr-6 text-sm  text-gray-500 dark:text-white hover:underline hidden md:block"
            >
              (555) 412-1234
            </a> */}
            <UserMenuBtn />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
