import { BASE_URL, ADD_FOOD_ITEM, UPDATE_FOOD_ITEM } from "../utils/constants";

const handleFetchRequest = async (url, method, data) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    let result;
    try {
      result = await response.json();
    } catch (jsonError) {
      if (!response.ok) {
        throw new Error(
          `Server error (${response.status}): Unable to parse response as JSON`
        );
      }
      throw jsonError;
    }

    if (response.ok) {
      return { success: true, message: result.message, data: result.data };
    } else {
      return {
        success: false,
        message: result.detail?.message || "Bad request",
        data: result.data,
      };
    }
  } catch (error) {
    console.error("Error in API request:", error);
    throw new Error(error.message || "Network error or server is unreachable");
  }
};

export const addFoodItem = (data) => {
  return handleFetchRequest(`${BASE_URL + ADD_FOOD_ITEM}`, "POST", data);
};

export const updateFoodItem = (data) => {
  return handleFetchRequest(`${BASE_URL + UPDATE_FOOD_ITEM}`, "PATCH", data);
};
