import React from "react";
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";

const GenderSelectField = ({
  name,
  control,
  label,
  rules,
  errors,
  defaultValue = "male",
  helpText = "",
}) => {
  const optionClass = "!py-2 md:!px-7 px-3 !text-sm";
  const genderOptions = [
    { label: "Male", value: "male", className: optionClass },
    { label: "Female", value: "female", className: optionClass },
    { label: "Other", value: "other", className: optionClass },
  ];

  return (
    <div className="field">
      <label htmlFor={name} className="block">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <>
            <SelectButton
              value={field.value}
              options={genderOptions}
              onChange={(e) => field.onChange(e.value)}
              className={`${
                fieldState.invalid ? "p-invalid" : ""
              } !shadow-none w-full`}
            />
            {helpText?.length > 0 && (
              <small className="block text-xs">{helpText}</small>
            )}
            {errors[name] && (
              <small className="p-error block">{errors[name].message}</small>
            )}
          </>
        )}
      />
    </div>
  );
};

export default GenderSelectField;
