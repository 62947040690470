import React from "react";
import ChipsField from "../../formFields/ChipsField";

const FoodFormOptionalFields = ({ control, errors }) => {
  const fields = [
    {
      name: "meal_tags",
      label: "Meal Tags",
      helpText: "use '_' inplace of space.",
    },
    { name: "health_tags", label: "Health Tags" },
    { name: "cuisine_tags", label: "Cuisine Tags" },
  ];

  return (
    <div className="grid gap-5">
      {fields.map((field) => (
        <ChipsField
          key={field.name}
          name={field.name}
          control={control}
          label={field.label}
          placeholder={`Enter ${field.label.toLowerCase()}`}
          errors={errors}
          keyfilter={/^[a-z_]*$/}
          helpText={field.helpText}
        />
      ))}
    </div>
  );
};

export default FoodFormOptionalFields;
