import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { useDialog } from "../../../contexts/DialogContext";
import { usePatientData } from "../../../hooks/usePatientData";
import {
  transformFormDataToAPI,
  transformPatientDataToForm,
} from "../../../utils/patientDataTransformers";
import Confirmation from "./Confirmation";
import PatientFormSteps from "./PatientFormSteps";
import PersonalDetailsForm from "./PersonalDetailsForm";

const PatientFormDialog = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [collectedData, setCollectedData] = useState({});
  const { dialogStates, closeDialog } = useDialog();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const patientId = dialogStates.patientForm.props?.user_id;
  const isVisible = dialogStates.patientForm.visible;
  const { patientData, loading, error } = usePatientData(patientId, isVisible);

  const renderActiveForm = () => {
    switch (activeIndex) {
      case 0:
        return (
          <PersonalDetailsForm
            control={control}
            errors={errors}
            patientId={patientId}
          />
        );
      // case 1:
      //   return <DietLifestyleForm control={control} errors={errors} />;
      // case 2:
      //   return <DiabetesHistoryForm control={control} errors={errors} />;
      case 1:
        return (
          <Confirmation
            collectedData={collectedData}
            onConfirm={handleFinalSubmit}
          />
        );
      default:
        return null;
    }
  };

  const handleStepSubmit = useCallback((data) => {
    setCollectedData((prevData) => ({ ...prevData, ...data }));
    setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
  }, []);

  const handleFinalSubmit = async () => {
    const transformedData = transformFormDataToAPI(collectedData);
    console.log(transformedData);

    try {
      setButtonLoading(true);
      if (patientId) {
        // await updatePatient(patientId, transformedData);
        // toast.success("Profile updated successfully.");
      } else {
        // await createPatient(transformedData);
        // toast.success("Profile created successfully.");
      }
      // closeDialog("patientForm", { refresh: true });
    } catch (e) {
      toast.error(
        "Some error occurred. Please try again later or contact our support."
      );
    } finally {
      setButtonLoading(false);
    }
  };

  const handlePrevious = useCallback(() => {
    if (activeIndex !== 0) setActiveIndex(activeIndex - 1);
  }, [activeIndex]);

  useEffect(() => {
    if (patientData) {
      const transformedData = transformPatientDataToForm(
        patientData?.patient_details
      );
      reset(transformedData);
    }
  }, [patientData, reset]);

  useEffect(() => {
    if (!isVisible) {
      reset({ first_name: "" }); // this is the hacky solution i found to reset all fields
      setActiveIndex(0);
      setCollectedData({});
    }
  }, [isVisible, reset]);

  return (
    <Dialog
      header="Add Patient"
      visible={dialogStates.patientForm.visible}
      style={{ width: "50vw" }}
      className="md:!w-[45vw] !w-[80vw]"
      maximizable={true}
      onHide={() => closeDialog("patientForm")}
      footer={
        activeIndex === 1 && (
          <div className="flex items-center justify-between mt-10">
            <Button
              label="Back"
              className="p-button-sm !py-2 !px-4"
              icon="pi pi-chevron-left"
              iconPos="left"
              onClick={handlePrevious}
            />
            <Button
              label="Submit"
              className="p-button-sm !py-2 !px-4"
              icon="pi pi-check"
              iconPos="left"
              loading={buttonLoading}
              onClick={handleSubmit(handleFinalSubmit)}
            />
          </div>
        )
      }
    >
      <PatientFormSteps
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />

      <LoadingOverlay
        active={loading}
        spinner={<ClipLoader />}
        className="!opacity-90 p-3 rounded-md overflow-hidden"
      >
        <div className="mt-8">
          <form
            key={`form-${Math.random()}`}
            onSubmit={handleSubmit(handleStepSubmit)}
          >
            {renderActiveForm()}
          </form>
        </div>
      </LoadingOverlay>
    </Dialog>
  );
};

export default PatientFormDialog;
