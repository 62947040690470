import React from "react";
import { Steps } from "primereact/steps";

const PatientFormSteps = ({ activeIndex, setActiveIndex }) => {
  const items = [
    { label: "Personal" },
    // { label: "Diet/Lifestyle" },
    // { label: "Diabetes History" },
    { label: "Confirmation" },
  ];

  return (
    <Steps
      model={items}
      activeIndex={activeIndex}
      onSelect={(e) => setActiveIndex(e.index)}
      readOnly={true}
    />
  );
};

export default PatientFormSteps;
