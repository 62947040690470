import Cookies from "js-cookie";
import React from "react";

import CommonProviders from "./CommonProviders";
import Sidebar from "./Sidebar/Sidebar";

const AppLayout = () => {
  console.log("is token there: ", Cookies.get("token"));

  const isAuth = Cookies.get("token") != undefined ? true : false;

  return (
    <CommonProviders>
      <Sidebar />
    </CommonProviders>
  );
};

export default AppLayout;
