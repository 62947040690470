import React from "react";
import SidebarMenuItem from "./SidebarMenuItem";

import MenuSVG from "jsx:../../assets/svg/menu.svg";
import CaregiverSVG from "jsx:../../assets/svg/caregiver.svg";
import PatientSVG from "jsx:../../assets/svg/patient.svg";
import HealthFacilitySVG from "jsx:../../assets/svg/health-facility.svg";
import OverviewSVG from "jsx:../../assets/svg/overview.svg";
import PackageSVG from "jsx:../../assets/svg/package.svg";
import MedicineSVG from "jsx:../../assets/svg/medicine.svg";
import FoodSVG from "jsx:../../assets/svg/food.svg";

const SidebarItems = () => {
  const menuItems = [
    { icon: <OverviewSVG className="h-6" />, text: "Overview", link: "/" },

    {
      icon: <PatientSVG className="h-6" />,
      text: "Patients",
      link: "/patients",
    },
    {
      icon: <CaregiverSVG className="h-6" />,
      text: "Caregivers",
      link: "/caregivers",
    },
    {
      icon: <HealthFacilitySVG className="h-6" />,
      text: "Health Facility",
      link: "/inbox",
    },
    {
      icon: <PackageSVG className="h-6" />,
      text: "Packages",
      link: "/packages",
    },
  ];

  const dataSourceItems = [
    {
      icon: <MedicineSVG className="h-6" />,
      text: "Medicines",
      link: "/medicines",
    },
    { icon: <FoodSVG className="h-6" />, text: "Food", link: "/food" },
  ];

  return (
    <div>
      {/* Menu items */}
      <ul className="space-y-2 font-medium">
        <p className="text-gray-500 text-sm">Menu Items</p>
        {menuItems.map((item, index) => (
          <SidebarMenuItem key={index} {...item} />
        ))}
      </ul>

      {/* Notifications */}
      <p className="pt-10 text-gray-500 text-sm">DataTables</p>
      <ul className="space-y-2 font-medium">
        {dataSourceItems.map((item, index) => (
          <SidebarMenuItem key={index} {...item} />
        ))}
      </ul>
    </div>
  );
};

export default SidebarItems;
