export const usePeriodOptions = () => {
  return [
    { label: "Last 24 Hours", value: "24-hours" },
    { label: "Last 7 Days", value: "7-days" },
    { label: "Last 14 Days", value: "14-days" },
    { label: "Last 28 Days", value: "28-days" },
    { label: "Last 60 Days", value: "60-days" },
    { label: "Custom", value: "custom" },
  ];
};
