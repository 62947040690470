import { Button } from "primereact/button";
import React from "react";
import { useWatch } from "react-hook-form";
import DateField from "../../formFields/DateField";
import GenderSelectField from "../../formFields/GenderSelectField";
import InputField from "../../formFields/InputField";
import NumberField from "../../formFields/NumberField";
import PhoneNumberField from "../../formFields/PhoneNumberField";
import ProfilePictureField from "../../formFields/ProfilePictureField";
import { validationRules } from "./fieldConfigurations";

const PersonalDetailsForm = ({ control, errors, patientId }) => {
  const gender = useWatch({ control, name: "gender" });

  return (
    <>
      <div className="grid grid-cols-2 gap-x-4 gap-y-7">
        <div className="col-span-2 flex justify-center">
          <ProfilePictureField
            name="profile_picture"
            control={control}
            label="Profile Picture"
            rules={validationRules.profilePicture}
            errors={errors}
            gender={gender}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <InputField
            name="first_name"
            control={control}
            label="First Name"
            placeholder="Enter your first name"
            rules={validationRules.firstName}
            errors={errors}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <InputField
            name="last_name"
            control={control}
            label="Last Name"
            placeholder="Enter your last name"
            rules={validationRules.lastName}
            errors={errors}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <InputField
            name="email_address"
            control={control}
            label="Email"
            iconClass="pi pi-envelope"
            placeholder="Enter your email address"
            rules={validationRules.email}
            errors={errors}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <PhoneNumberField
            name="phone_number"
            control={control}
            label="Phone Number"
            placeholder="Enter your phone number"
            disabled={patientId !== undefined}
            rules={validationRules.phoneNumber}
            errors={errors}
            helpText={patientId && "You cannot edit phone number."}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <GenderSelectField
            name="gender"
            control={control}
            label="Gender"
            placeholder="Enter your gender"
            rules={validationRules.gender}
            errors={errors}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <DateField
            name="date_of_birth"
            control={control}
            label="Date of Birth"
            placeholder="Enter your date of birth"
            rules={validationRules.dob}
            errors={errors}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <NumberField
            name="waist"
            control={control}
            label="Waist"
            unit="in Cm"
            placeholder="Enter your waist"
            rules={validationRules.waist}
            errors={errors}
            mode="decimal"
            suffix=" Cm"
            minFractionDigits={1}
            maxFractionDigits={1}
          />
        </div>

        <div className="md:col-span-1 col-span-2">
          <NumberField
            name="height"
            control={control}
            label="Height"
            unit="ft inch"
            placeholder="Enter your height"
            rules={validationRules.height}
            errors={errors}
            mode="decimal"
            suffix=" ft inch"
            minFractionDigits={1}
            maxFractionDigits={1}
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <NumberField
            name="weight"
            control={control}
            label="Weight"
            unit="in kgs"
            placeholder="Enter your weight"
            rules={validationRules.weight}
            errors={errors}
            mode="decimal"
            suffix=" Kgs"
            minFractionDigits={1}
            maxFractionDigits={1}
          />
        </div>
      </div>

      <div className="flex items-center justify-end mt-10">
        <Button
          label="Next"
          className="p-button-sm !py-2 !px-4"
          icon="pi pi-chevron-right"
          iconPos="right"
        />
      </div>
    </>
  );
};

export default PersonalDetailsForm;
