import React, { useState } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

import AppStoreDownloadSVG from "jsx:../assets/svg/appstore-download.svg";
import PlayStoreDownloadPNG from "../assets/images/playstore-download.png";

import { LOGO_URL } from "../utils/constants";

// ref: https://flowbite.com/blocks/marketing/login/
const Login = () => {
  const [isEmailSelected, setIsEmailSelected] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      phone_number: "",
      password: "",
    },
    validate: (data) => {
      let errors = {};

      if (isEmailSelected && !data.email) {
        console.log("==> entered if email");
        errors.email = "Email is required.";
      } else if (
        isEmailSelected &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        console.log("==> entered else if email");

        errors.email = "Invalid email address. E.g. example@7sugar.com";
      }

      if (!isEmailSelected && !data.phone_number) {
        errors.phone_number = "Phone number is required.";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      // setFormData(data);
      setShowMessage(true);

      console.log("==> data: ", data);
      // formik.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  // Functions
  const toggleEmailSelection = () => {
    setIsEmailSelected(!isEmailSelected);
  };

  const renderTextField = () => {
    if (isEmailSelected) {
      return (
        <div className="field">
          <label htmlFor="email" className="block">
            Email
          </label>
          <InputText
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            autoFocus
            aria-describedby="email-help"
            className={classNames(
              "p-invalid !bg-gray-50 border !border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full !p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
              { "p-invalid": isFormFieldValid("email") }
            )}
            placeholder="name@7sugar.com"
          />
          {getFormErrorMessage("email")}
        </div>
      );
    }
    return (
      <div className="field">
        <label htmlFor="phone-number" className="block">
          Your Phone Number
        </label>
        <InputMask
          id="phone-number"
          name="phone_number"
          mask="+99-9999999999"
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          aria-describedby="phone-number-help"
          className={classNames(
            "p-invalid !bg-gray-50 border !border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full !p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
            { "p-invalid": isFormFieldValid("phone_number") }
          )}
          placeholder="+91-7710705052"
        />
        {getFormErrorMessage("phone_number")}
      </div>
    );
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 h-full">
      <div className="flex flex-col items-center justify-center h-full px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="w-8 h-8 mr-2" src={LOGO_URL} alt="7sugar-logo"></img>
          7Sugar Dashboard
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={formik.handleSubmit}
            >
              {renderTextField()}
              <div className="flex justify-end !my-2">
                <Button
                  label={
                    isEmailSelected
                      ? "use phone number instead"
                      : "use email address instead"
                  }
                  className="p-button-text !p-0 !text-xs hover:underline text-gray-500 hover:!shadow-none"
                  type="button"
                  onClick={toggleEmailSelection}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <Password
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  id="password"
                  name="password"
                  className="w-full"
                  inputClassName="!bg-gray-50 border !border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full !p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="enter password"
                  toggleMask
                />
                {getFormErrorMessage("password")}
              </div>
              <Button
                type="submit"
                label="Sign in"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              />
            </form>
            <div className="pt-10">
              <p className="text-sm text-gray-500">
                Download Our Caregiver App
              </p>
              <div className="flex items-center justify-around m-[-1rem]">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.sensai.sevensugar.caregiver"
                >
                  <img src={PlayStoreDownloadPNG} className="w-36" alt="" />
                </a>
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/7sugar-caregiver/id1504205512"
                >
                  <AppStoreDownloadSVG className="w-36" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
