import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import overviewApiService from "../services/overviewApiService";

const DetailedMealAnalysis = ({ selectedPeriod, selectedFacilities }) => {
  const [loading, setLoading] = useState(false);

  const { fetchMealStats } = overviewApiService({
    selectedPeriod,
    selectedFacilities,
  });

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [], // Dates will go here
      },
      yaxis: {
        title: {
          text: "Count (meals)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " meals";
          },
        },
      },
    },
    series: [
      {
        name: "Total Meals",
        data: [], // Total meals data will go here
      },
      {
        name: "Analyzed Meals",
        data: [], // Analyzed meals data will go here
      },
      {
        name: "Meals with Images",
        data: [], // Meals with images data will go here
      },
      {
        name: "Ambiguous Meals",
        data: [], // Ambiguous meals data will go here
      },
    ],
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await fetchMealStats();

      if (!result.success) {
        toast.error("Failed to fetch data.");
        return;
      }

      setChartData((prevState) => ({
        ...prevState,
        options: {
          ...prevState.options,
          xaxis: {
            categories: result.data.map((i) =>
              moment(i.date).format("MMM Do, YY")
            ),
          },
        },
        series: [
          { name: "Total Meals", data: result.data.map((i) => i.total_meals) },
          {
            name: "Analyzed Meals",
            data: result.data.map((i) => i.analyzed_meals),
          },
          {
            name: "Meals with Images",
            data: result.data.map((i) => i.meals_with_images),
          },
          {
            name: "Ambiguous Meals",
            data: result.data.map((i) => i.ambiguous_meals),
          },
        ],
      }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFacilities && selectedPeriod) {
      fetchData();
    }
  }, [selectedFacilities, selectedPeriod]);

  return (
    <LoadingOverlay
      active={loading}
      spinner={<ClipLoader />}
      className="!opacity-90 h-[80%]"
    >
      <div className="h-full">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height="100%"
        />
      </div>
    </LoadingOverlay>
  );
};

export default DetailedMealAnalysis;
