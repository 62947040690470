import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDialog } from "../../../contexts/DialogContext";
import { addFoodItem, updateFoodItem } from "../../../services/foodService";
import FoodFormFields from "./FoodFormFields";
import { defaultValues } from "./fieldConfigurations";

const FoodFormDialog = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { dialogStates, closeDialog } = useDialog();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { foodData, categoryList } = dialogStates.foodForm.props;
  const isVisible = dialogStates.foodForm.visible;

  const handleFinalSubmit = async (data) => {
    console.log("handleFinalSubmit: ", data);
    const processedData = {
      ...data,
      primary_category: data.primary_category.value,
    };

    try {
      setButtonLoading(true);

      const apiFunction = foodData ? updateFoodItem : addFoodItem;
      const result = await apiFunction(processedData);

      if (result.success) {
        toast.success(result.message);
        closeDialog("foodForm", { refresh: true });
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred.");
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (foodData) {
      reset(foodData);
    }
  }, [foodData, reset]);

  useEffect(() => {
    if (!isVisible) {
      reset(defaultValues);
    }
  }, [isVisible, reset]);

  return (
    <Dialog
      header={!foodData ? "Add Food Item" : "Update Food Item"}
      visible={dialogStates.foodForm.visible}
      style={{ width: "50vw" }}
      className="md:!w-[30vw] !w-[80vw]"
      maximizable={false}
      dismissableMask={true}
      onHide={() => closeDialog("foodForm")}
      footer={
        <Button
          label={!foodData ? "Add Food" : "Save Changes"}
          icon={!foodData ? "pi pi-plus" : "pi pi-check"}
          className="p-button-outlined p-button-sm !py-2"
          loading={buttonLoading}
          onClick={handleSubmit(handleFinalSubmit)}
        />
      }
    >
      <div className="mt-8">
        <form key="dialog-food-form">
          <FoodFormFields
            control={control}
            errors={errors}
            data={foodData}
            categoryList={categoryList}
          />
        </form>
      </div>
    </Dialog>
  );
};

export default FoodFormDialog;
