import React from "react";
import NumberField from "../../formFields/NumberField";
import { validationRules } from "./fieldConfigurations";
import DropdownField from "../../formFields/DropdownField";
import { servingUnits, measuringUnits } from "../../../utils/units";

const FoodFormMeasuringFields = ({ control, errors }) => {
  return (
    <div className="grid grid-cols-2 gap-5">
      <div className="col-span-2 md:col-span-1">
        <NumberField
          name="quantity"
          control={control}
          label="Serving Quatity"
          placeholder="Enter quantity"
          rules={validationRules.quantity}
          errors={errors}
          mode="decimal"
          minFractionDigits={1}
          maxFractionDigits={1}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <DropdownField
          name="serving_unit"
          control={control}
          label="Unit"
          placeholder="Enter serving unit"
          rules={validationRules.serving_unit}
          errors={errors}
          options={servingUnits}
          filter={true}
        />
      </div>
      {/* <div className="col-span-2 md:col-span-1">
        <NumberField
          name="measuring_quantity"
          control={control}
          label="Measuring Quatity"
          placeholder="Enter quantity"
          rules={validationRules.quantity}
          errors={errors}
          mode="decimal"
          minFractionDigits={1}
          maxFractionDigits={1}
        />
      </div> */}
      <div className="col-span-2">
        <DropdownField
          name="measuring_unit"
          control={control}
          label="Measuing Unit"
          placeholder="Enter measuring unit"
          rules={validationRules.measuring_unit}
          errors={errors}
          options={measuringUnits}
          filter={true}
        />
      </div>
    </div>
  );
};

export default FoodFormMeasuringFields;
