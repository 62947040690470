import { PrimeReactProvider } from "primereact/api";
import React from "react";

import { ConfirmDialog } from "primereact/confirmdialog";
import { ConfirmPopup } from "primereact/confirmpopup";
import { ToastContainer } from "react-toastify";
import { DialogProvider } from "../contexts/DialogContext";
import { PrintViewProvider } from "../contexts/PrintViewContext";
import CommonAncestorComponent from "./CommonAncestorComponent";

const CommonProviders = ({ children }) => {
  return (
    <>
      <PrimeReactProvider>
        <PrintViewProvider>
          <DialogProvider>
            <CommonAncestorComponent />
            <ToastContainer />
            <ConfirmPopup />
            {children}
            <ConfirmDialog />
          </DialogProvider>
        </PrintViewProvider>
      </PrimeReactProvider>
    </>
  );
};

export default CommonProviders;
