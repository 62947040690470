import {
  cmToFeetInches,
  feetInchesToCm,
  maskPhoneNumber,
  revertMaskedPhoneNumberFormat,
} from "./conversionUtils";
import moment from "moment";

// Function to transform backend data to frontend format
export const transformPatientDataToForm = (data) => {
  console.log(data);
  return {
    ...data,
    date_of_birth: new Date(data.date_of_birth),
    height: cmToFeetInches(data.height),
    phone_number: maskPhoneNumber(data.phone_number),
  };
};

// Function to transform frontend data to backend format
export const transformFormDataToAPI = (data) => {
  const {
    first_name,
    last_name,
    date_of_birth,
    email_address,
    gender,
    height,
    waist,
    weight,
    profile_picture,
    phone_number,
  } = data;

  return {
    first_name,
    last_name,
    full_name: `${first_name} ${last_name}`,
    date_of_birth: new Date(date_of_birth).getTime(),
    email_address,
    gender,
    height: feetInchesToCm(height),
    waist,
    weight,
    profile_picture,
    phone_number: revertMaskedPhoneNumberFormat(phone_number),
  };
};
