import React from "react";
import NumberField from "../../formFields/NumberField";
import { validationRules } from "./fieldConfigurations";

const macronutrients = [
  {
    name: "calories",
    label: "Calories",
    unit: "KCals",
    validationRule: validationRules.calories,
  },
  {
    name: "carbs",
    label: "Carbs",
    unit: "grams",
    validationRule: validationRules.carbs,
  },
  {
    name: "protein",
    label: "Protein",
    unit: "grams",
    validationRule: validationRules.protein,
  },
  {
    name: "fiber",
    label: "Fiber",
    unit: "grams",
    validationRule: validationRules.fiber,
  },
  {
    name: "fat",
    label: "Fat",
    unit: "grams",
    validationRule: validationRules.fat,
  },
];

const FoodFormMacroNutrientsFields = ({ control, errors }) => {
  return (
    <div className="grid gap-5">
      {macronutrients.map((nutrient) => (
        <NumberField
          key={nutrient.name}
          name={nutrient.name}
          control={control}
          label={nutrient.label}
          unit={`in ${nutrient.unit}`}
          placeholder={`Enter ${nutrient.label.toLowerCase()}`}
          rules={nutrient.validationRule}
          errors={errors}
          mode="decimal"
          suffix={` ${nutrient.unit}`}
          minFractionDigits={1}
          maxFractionDigits={2}
        />
      ))}
    </div>
  );
};

export default FoodFormMacroNutrientsFields;
