import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { useDialog } from "../contexts/DialogContext";
import useCGMDataProcessor from "../hooks/useCGMDataProcessor";
import CustomFileUpload from "./CustomFileUpload";
import { uploadCGMData } from "../services/cgmUploadService";

const UploadCGMDataDialog = () => {
  const [loading, setLoading] = useState(false);
  const { dialogStates, closeDialog } = useDialog();
  const { user_id } = dialogStates.uploadCGM.props;

  if (!user_id && dialogStates.uploadCGM.visible) {
    toast.error("User ID is missing.");
    return null;
  }

  const onSuccess = async (data) => {
    try {
      setLoading(true);

      const { success, error } = await uploadCGMData(data, user_id);

      if (success) {
        toast.success("Data uploaded successfully.");
      } else {
        toast.error(
          `Some error occurred, please contact our team. Error: ${error}`
        );
      }

      closeDialog("uploadCGM");
    } finally {
      setLoading(false);
    }
  };

  const onError = (message) => {
    toast.error(message);
  };

  const { processFile } = useCGMDataProcessor(onSuccess, onError);

  const handleFileUpload = (file) => {
    if (file) {
      const { processFile } = useCGMDataProcessor(onSuccess, onError);
      processFile(file);
    }
  };

  const showConfirmationDialog = (file) => {
    confirmDialog({
      message: "Are you sure you want to upload this file?",
      header: "Upload Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => handleFileUpload(file),
      reject: () => console.log("File upload cancelled."),
    });
  };

  const onFileSelect = (event) => {
    const file = event.files?.[0];
    if (file) {
      showConfirmationDialog(file);
    } else {
      toast.error("No file selected.");
    }
  };

  return (
    <Dialog
      header="CGM Data Upload"
      visible={dialogStates.uploadCGM.visible}
      onHide={() => closeDialog("uploadCGM")}
      className="md:w-[35vw] w-[80vw]"
    >
      <LoadingOverlay
        active={loading}
        spinner={<ClipLoader />}
        className="!opacity-90"
      >
        <CustomFileUpload onUploadCallback={onFileSelect} />
      </LoadingOverlay>
    </Dialog>
  );
};

export default UploadCGMDataDialog;
