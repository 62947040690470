import moment from "moment";
import React from "react";
import { capitalizeWords } from "../../../utils/strings";

const Confirmation = ({ collectedData }) => {
  const dataEntries = {
    basic_information: [
      { label: "First Name", value: collectedData?.first_name },
      { label: "Last Name", value: collectedData?.last_name },
      { label: "Email", value: collectedData?.email_address },
      { label: "Phone Number", value: collectedData?.phone_number },
      {
        label: "Date of Birth",
        value: moment(collectedData?.date_of_birth).format("DD/MM/YYYY"),
      },
      { label: "Gender", value: collectedData?.gender },
      { label: "Waist", value: `${collectedData?.waist} Cm` },
      { label: "Height", value: `${collectedData?.height} ft inch` },
      { label: "Weight", value: `${collectedData?.weight} kgs` },
    ],
  };

  return (
    <>
      {Object.entries(dataEntries).map(([category, entries]) => (
        <React.Fragment key={category}>
          <div className="mb-10">
            <h3 className="font-bold text-2xl mt-3">
              {capitalizeWords(category)}
            </h3>
            <div className="border rounded-lg p-5">
              <ul>
                {entries.map((entry, index) => (
                  <li key={index} className="my-2">
                    <span className="font-normal">{entry.label}: </span>
                    <span className="font-bold text-lg">
                      {entry.value || "-"}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default Confirmation;
