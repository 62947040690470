import moment from "moment-timezone";

export const useTimeChange = () => {
  const timezone = moment.tz.guess();
  const now = moment.tz(timezone);

  const onTimeChangeInHours = (hours) => ({
    start: moment(now).subtract(hours, "hours").valueOf(),
    end: moment(now).valueOf(),
  });

  const onTimeChangeInDays = (days) => ({
    start: moment(now).subtract(days, "days").valueOf(),
    end: moment(now).valueOf(),
  });

  const getDateTime = (value) => {
    switch (value) {
      case "24-hours":
        return onTimeChangeInHours(24);
      case "7-days":
        return onTimeChangeInDays(7);
      case "14-days":
        return onTimeChangeInDays(14);
      case "28-days":
        return onTimeChangeInDays(28);
      case "60-days":
        return onTimeChangeInDays(60);
    }
  };

  return { getDateTime };
};
