import React, { useState } from "react";
import { useTimeChange } from "../hooks/useTimeChange";
import DetailedMealStatsChart from "./DetailedMealStatsChart";
import HeaderWithPeriodSelector from "./HeaderWithPeriodSelector";
import HealthFacilityDropdown from "./HealthFacilityDropdown";

const DetailedMealStats = () => {
  const { getDateTime } = useTimeChange();
  const defaultPeriod = "7-days";
  const [selectedFacilities, setSelectedFacilities] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(
    getDateTime(defaultPeriod)
  );

  return (
    <div className="h-[90%]">
      <HeaderWithPeriodSelector
        header="Meals Stats"
        defaultPeriod={defaultPeriod}
        setSelectedPeriod={setSelectedPeriod}
      />

      <HealthFacilityDropdown
        selectedFacilities={selectedFacilities}
        setSelectedFacilities={setSelectedFacilities}
      />

      <DetailedMealStatsChart
        selectedPeriod={selectedPeriod}
        selectedFacilities={selectedFacilities}
      />
    </div>
  );
};

export default DetailedMealStats;
