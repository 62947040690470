import { parseCSV, parseTextFile } from "../utils/CGMDataParsers";
import { toast } from "react-toastify";

const useCGMDataProcessor = (onSuccess, onError) => {
  const processFile = (file) => {
    const reader = new FileReader();

    reader.onerror = () =>
      toast.error("An error occurred while reading the file.");

    reader.onload = (event) => {
      const content = event.target.result;
      if (file.type == "text/csv") {
        parseCSV(content, onSuccess, onError);
      } else if (file.type == "text/txt") {
        parseTextFile(content, onSuccess, onError);
      } else {
        toast.error(
          "Invalid file type. Please select a plain text or CSV file."
        );
      }
    };

    reader.readAsText(file);
  };

  return { processFile };
};

export default useCGMDataProcessor;
