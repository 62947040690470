import React, { useState } from "react";
import HealthFacilityDropdown from "./HealthFacilityDropdown";
import DetailedMealStats from "./DetailedMealStats";

const Home = () => {
  return (
    <div className="grid grid-cols-2 gap-3">
      <div className="col-span-2 md:col-span-1 bg-gray-100 rounded-lg p-5 h-[28rem]">
        <DetailedMealStats />
      </div>
      <div className="col-span-2 md:col-span-1 bg-gray-100 rounded-lg p-5 h-[28rem]">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex mollitia
        asperiores odio rem animi tempora magni, in et aliquam nihil odit vel,
        assumenda quasi beatae voluptatum velit, nam non distinctio! Lorem ipsum
        dolor sit, amet consectetur adipisicing elit. Ex mollitia asperiores
        odio rem animi tempora magni, in et aliquam nihil odit vel, assumenda
        quasi beatae voluptatum velit, nam non distinctio! Lorem ipsum dolor
        sit, amet consectetur adipisicing elit. Ex mollitia asperiores odio rem
        animi tempora magni, in et aliquam nihil odit vel, assumenda quasi
        beatae voluptatum velit, nam non distinctio!
      </div>
    </div>
  );
};

export default Home;
