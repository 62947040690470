import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { LOGO_URL } from "../utils/constants";

import { usePrintView } from "../contexts/PrintViewContext";

const PrintLayout = () => {
  const { setIsPrintView } = usePrintView();
  useEffect(() => {
    setIsPrintView(true);

    const viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute("content", "width=1024");

    return () => {
      viewport.setAttribute("content", "width=device-width, initial-scale=1.0");
    };
  }, []);

  return <Outlet />;
};

export default PrintLayout;
