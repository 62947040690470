import React from "react";
import PeriodDropdown from "./PeriodDropdown";

const HeaderWithPeriodSelector = ({
  header,
  defaultPeriod,
  setSelectedPeriod,
}) => {
  return (
    <div className="flex items-center justify-between">
      <h1 className="font-semibold text-xl">{header}</h1>
      <PeriodDropdown
        defaultPeriod={defaultPeriod}
        setSelectedPeriod={setSelectedPeriod}
        className="!w-52"
      />
    </div>
  );
};

export default HeaderWithPeriodSelector;
