import { FileUpload } from "primereact/fileupload";
import React, { useRef } from "react";
import "./CustomFileUpload.css";

const CustomFileUpload = ({ onUploadCallback }) => {
  const fileUploadRef = useRef(null);

  const emptyTemplate = () => {
    return (
      <div className="flex items-center justify-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="m-5 mr-0"
        >
          Drag and Drop your CSV File Here (max 2Mb.)
        </span>
      </div>
    );
  };

  return (
    <FileUpload
      ref={fileUploadRef}
      id="file-upload"
      accept=".csv"
      mode="advanced"
      maxFileSize={1000000}
      customUpload
      disabled={false}
      emptyTemplate={emptyTemplate}
      uploadHandler={onUploadCallback}
    />
  );
};

export default CustomFileUpload;
