import React from "react";
import UserMenuItem from "./UserMenuItem";

const UserMenuBtn = () => {
  const userInfo = {
    name: "Mukhtar Ahmed",
    email: "mukhtar@7sugar.com",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfVh1kRk18Z1Hjhhb4ptkSR_21NKsyJfjvmA&usqp=CAU",
  };
  return (
    <div className="flex items-center md:order-2">
      <button
        type="button"
        className="flex items-center mr-3 text-sm rounded-md md:mr-0 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="user-menu-button"
        aria-expanded="false"
        data-dropdown-toggle="user-dropdown"
        data-dropdown-placement="bottom"
      >
        <span className="sr-only">Open user menu</span>
        <img
          className="w-9 h-9 rounded-full"
          src={userInfo.profilePic}
          alt="photo pic"
        />
        <span className="mx-3 text-base hidden md:block">{userInfo.name}</span>
      </button>
      <div
        className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
        id="user-dropdown"
      >
        <UserMenuItem name={userInfo.name} email={userInfo.email} />
      </div>
    </div>
  );
};

export default UserMenuBtn;
