import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const BodyLayout = () => {
  return (
    <div className="p-4 sm:ml-64 h-screen overflow-hidden">
      <Navbar />
      <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 h-[91%] overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default BodyLayout;
