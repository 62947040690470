import { SelectButton } from "primereact/selectbutton";
import React from "react";
import { Controller } from "react-hook-form";

const GlycemicIndexField = ({ control, rules, errors, allowEmpty = false }) => {
  const name = "glycemic_index";
  const defaultValue = "medium";
  const optionClass = "!py-2 md:!px-7 px-3 !text-sm";
  const options = [
    { label: "Low", value: "low", className: optionClass },
    { label: "Medium", value: "medium", className: optionClass },
    { label: "High", value: "high", className: optionClass },
  ];

  return (
    <div className="field">
      <label htmlFor={name} className="block">
        Glycemic Index
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <SelectButton
              value={field.value}
              defaultValue={defaultValue}
              options={options}
              onChange={(e) => field.onChange(e.value)}
              allowEmpty={allowEmpty}
              className={`${
                fieldState.invalid ? "p-invalid" : ""
              } !shadow-none w-full`}
            />
            {errors[name] && (
              <small className="p-error block">{errors[name].message}</small>
            )}
          </>
        )}
      />
    </div>
  );
};

export default GlycemicIndexField;
