import React from "react";
import { Controller } from "react-hook-form";
import { Chips } from "primereact/chips";

const ChipsField = ({
  name,
  control,
  label,
  iconClass,
  placeholder,
  rules,
  errors,
  keyfilter,
  defaultValue = "",
  helpText = "",
}) => {
  return (
    <div className="field">
      <label htmlFor={name} className="block">
        {label}
      </label>
      <span className=" p-input-icon-right w-full">
        {iconClass && <i className={iconClass} />}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <Chips
                {...field}
                className={`${
                  fieldState.invalid ? "p-invalid" : ""
                } p-inputtext-sm w-full`}
                style={{ width: "100%", padding: "0.5rem" }}
                placeholder={placeholder}
                separator=","
                keyfilter={keyfilter}
                allowDuplicate={false}
                addOnBlur={true}
                max={5}
              />
            </>
          )}
        />
      </span>
      {helpText?.length > 0 && (
        <small className="block text-xs">{helpText}</small>
      )}
      {errors[name] && (
        <small className="p-error block">{errors[name].message}</small>
      )}
    </div>
  );
};

export default ChipsField;
