import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";
import { useDialog } from "../contexts/DialogContext";
import { useTimeChange } from "../hooks/useTimeChange";
import { usePeriodOptions } from "../hooks/usePeriodOptions";

const PeriodDropdown = ({
  defaultPeriod,
  setSelectedPeriod,
  className = "!w-96",
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultPeriod); // for dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { openDialog } = useDialog();
  const periodOptions = usePeriodOptions();
  const { getDateTime } = useTimeChange();

  const onDropdownShow = () => {
    setIsDropdownOpen(true);
  };

  const onDropdownHide = () => {
    setIsDropdownOpen(false);

    if (selectedOption === "custom") {
      openDialog("calendar", {
        setSelectedPeriod,
      });
    }
  };

  const onChange = (e) => {
    setSelectedOption(e.value);

    if (e.value === "custom") {
      openDialog("calendar", {
        setSelectedPeriod,
      });
    } else {
      setSelectedPeriod(getDateTime(e.value));
    }
  };

  return (
    <Dropdown
      value={selectedOption}
      options={periodOptions}
      onChange={(e) => onChange(e)}
      onShow={onDropdownShow}
      onHide={onDropdownHide}
      placeholder="Select a Period"
      className={`${className} text-semibold`}
    />
  );
};

export default PeriodDropdown;
