import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppLayout from "./AppLayout";
import PrintLayout from "./PrintLayout";
import CommonProviders from "./CommonProviders";

const LayoutWrapper = () => {
  const location = useLocation();
  const [isExport, setIsExport] = useState(false);

  useEffect(() => {
    setIsExport(location.hash === "#export");
  }, [location]);

  return isExport ? (
    <CommonProviders>
      <PrintLayout />
    </CommonProviders>
  ) : (
    <AppLayout />
  );
};

export default LayoutWrapper;
