import { createContext, useContext } from "react";

const DietReportContext = createContext();

export const DietReportProvider = ({ children }) => {
  return (
    <DietReportContext.Provider value={{}}>
      {children}
    </DietReportContext.Provider>
  );
};

export const useDietReport = () => {
  return useContext(DietReportContext);
};
