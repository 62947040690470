export const cmToFeetInches = (cm) => {
  const inches = cm / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12); // Round to nearest inch
  return `${feet}.${remainingInches}`;
};

export const feetInchesToCm = (feetInches) => {
  const feet = Math.floor(feetInches);
  const inches = (feetInches - feet) * 10;
  const totalInches = feet * 12 + inches;
  const cm = totalInches * 2.54;
  return cm;
};

export const maskPhoneNumber = (number) => {
  number = number.replace("+", "");

  // Extract the country code and the rest of the number
  const countryCode = number.substring(0, 2);
  const mainNumber = number.substring(2);

  // Insert '-' after the 5th digit of the main number
  const formattedNumber =
    mainNumber.substring(0, 5) + "-" + mainNumber.substring(5);

  return countryCode + "-" + formattedNumber;
};

export const revertMaskedPhoneNumberFormat = (formattedNumber) => {
  // Remove the '-' characters
  const number = formattedNumber.replace(/-/g, "");
  return "+" + number;
};
