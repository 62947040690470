import React from "react";
import { Link } from "react-router-dom";
import { LOGO_URL } from "../../utils/constants";

const SidebarHeader = () => {
  return (
    <Link to="/" className="flex items-center pl-2.5 mt-5 mb-12">
      <img src={LOGO_URL} className="h-6 mr-3 sm:h-7" alt="7Sugar Logo" />
      <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
        7Sugar
      </span>
    </Link>
  );
};

export default SidebarHeader;
