import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import healthFacilityApiService from "../services/healthFacilityApiService";

const HealthFacilityDropdown = ({
  selectedFacilities,
  setSelectedFacilities,
  isMultiSelect = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [healthFacilitiesList, setHealthFacilitiesList] = useState(null);

  const { fetchHealthFacilities } = healthFacilityApiService();

  const loadHealthFacilities = async () => {
    setLoading(true);
    try {
      const response = await fetchHealthFacilities();

      if (response.success) {
        const formattedFacilities = response.data?.healthfacilities.map(
          (facility) => ({
            name: facility.name,
            value: facility.healthfacility_id,
          })
        );
        setHealthFacilitiesList(formattedFacilities);
      } else {
        toast.error("Failed to fetch health facilities.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHealthFacilities();
  }, []);

  const dropdownProps = useMemo(
    () => ({
      value: selectedFacilities,
      options: healthFacilitiesList,
      onChange: (e) => setSelectedFacilities(e.value),
      placeholder: "Select a Health Facility",
      optionLabel: "name",
      filter: true,
      showClear: true,
      //   loading,
      ariaLabel: isMultiSelect
        ? "Select multiple health facilities"
        : "Select a health facility",
    }),
    [
      selectedFacilities,
      healthFacilitiesList,
      loading,
      isMultiSelect,
      setSelectedFacilities,
    ]
  );

  return isMultiSelect ? (
    <MultiSelect
      {...dropdownProps}
      className="w-full my-3"
      panelClassName="w-96 break-all"
      selectionLimit={3}
      showSelectAll={false}
    />
  ) : (
    <Dropdown
      {...dropdownProps}
      className="w-full my-3"
      panelClassName="w-96 break-all"
    />
  );
};

export default HealthFacilityDropdown;
