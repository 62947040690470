import React from "react";
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

const DateField = ({
  name,
  control,
  label,
  placeholder,
  rules,
  errors,
  defaultValue = "",
  helpText = "",
}) => {
  return (
    <div className="field">
      <label htmlFor={name} className="block">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <>
            <Calendar
              {...field}
              className={`${fieldState.invalid ? "p-invalid" : ""} w-full`}
              inputClassName="!p-[0.55625rem]"
              placeholder={placeholder}
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              showIcon
              maxDate={new Date()}
            />
            {helpText?.length > 0 && (
              <small className="block text-xs">{helpText}</small>
            )}
            {errors[name] && (
              <small className="p-error block">{errors[name].message}</small>
            )}
          </>
        )}
      />
    </div>
  );
};

export default DateField;
