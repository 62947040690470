import React from "react";

const UserMenuItem = ({ name, email }) => {
  const menuItems = [
    { text: "Settings", link: "#" },
    { text: "Earnings", link: "#" },
    { text: "Sign out", link: "#" },
  ];

  return (
    <div className="px-4 py-3">
      <span className="block text-sm text-gray-900 dark:text-white">
        {name}
      </span>
      <span className="block text-sm text-gray-500 truncate dark:text-gray-400">
        {email}
      </span>
      <ul className="py-2" aria-labelledby="user-menu-button">
        {menuItems.map((item, index) => (
          <li key={index}>
            <a
              href={item.link}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white rounded-md"
            >
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserMenuItem;
