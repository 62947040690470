export const validationRules = {
  food_name: {
    required: "Food Name is required.",
    min: {
      value: 3,
      message: "Food name must be at least 3 characters long",
    },
    max: {
      value: 50,
      message: "Food name must be 50 characters or less",
    },
  },
  food_id: {
    required: "Food Id is required.",
    min: {
      value: 3,
      message: "Food id must be at least 3 characters long",
    },
    max: {
      value: 50,
      message: "Food id must be 50 characters or less",
    },
  },
  primary_category: {
    required: "Category is required.",
  },
  glycemic_index: {
    required: "Glycemic Index is required.",
  },
  calories: {
    required: "Calories is required.",
  },
  carbs: {
    required: "Carbs is required.",
  },
  protein: {
    required: "Protein is required.",
  },
  fat: {
    required: "Fat is required.",
  },
  fiber: {
    required: "Fiber is required.",
  },
  quantity: {
    required: "Quantity is required.",
  },
  serving_unit: {
    required: "Serving Unit is required.",
  },
  measuring_unit: {
    required: "Measuring Unit is required.",
  },
};

export const defaultValues = {
  food_name: "",
  food_id: "",
  primary_category: null,
  glycemic_index: "medium",
  quantity: null,
  serving_unit: null,
  measuring_unit: null,
  calories: null,
  carbs: null,
  protein: null,
  fat: null,
  fiber: null,
  calcium: null,
  iron: null,
  phosphorus: null,
  potassium: null,
  zinc: null,
  meal_tags: [],
  health_tags: [],
  cuisine_tags: [],
};
