export const servingUnits = [
  "cup",
  "Number",
  "Serving",
  "bar",
  "bottle",
  "bowl",
  "bun",
  "can",
  "chips",
  "clove",
  "cube",
  "cup",
  "egg",
  "fillet",
  "glass",
  "glass ",
  "inch ",
  "katori",
  "large",
  "medium",
  'medium 2"dia',
  'medium 8" long',
  "medium corn",
  "no",
  "number",
  "number ",
  "number  ",
  'number (10")',
  'number (12")',
  'number (2")',
  'number (4")',
  'number (5")',
  'number (6")',
  'number (6"inch)',
  'number (7")',
  'number (8")',
  'number (9")',
  "number (kutti)",
  "number (large)",
  "number (medium)",
  "number (regular)",
  "number (small)",
  'number 5"inch',
  'number 6"',
  'number 9"',
  "number large",
  "number medium",
  'number medium 7"long',
  "number( without stuffing)",
  'number(6")',
  "number(medium)",
  "number(regular)",
  "numer",
  "ounce",
  "ox",
  "oz",
  "oz ",
  "ozz",
  "package",
  "packet",
  "peas",
  "peice",
  "piece",
  "piece ",
  "piece (large)",
  "piece(medium)",
  "piece(small)",
  "pieces",
  "plate",
  "plate (6 pieces)",
  "plate (with 2 poori)",
  "plate(2 pav + bhaji)",
  "regular (6')",
  "roti",
  "sclice",
  "scoop",
  "serve ",
  "serve(2 bhature+1 katori chole)",
  "serving",
  "serving ",
  "serving  ",
  "serving (3 vade+ 1 katori curry)",
  "serving (collins glass)",
  "serving unit",
  "sesrving",
  "shake",
  "shot",
  "skewer",
  "slice",
  "slice (regular)",
  "slice (thick)",
  "slice medium",
  "slice raw",
  "slices",
  "square",
  "stick small",
  "tablespoon",
  "tbsp",
  "tbsp (chopped)",
  "teaspoon",
  "teaspoons",
  "toast",
  "tortilla",
  "tsp",
];

export const measuringUnits = [
  "Scoop",
  "cm",
  "g",
  "ggm",
  "gm",
  "gml",
  "gms",
  "gn",
  "inches",
  "ml",
  "number",
];
