export const capitalizeWords = (str) => {
  if (!str || typeof str !== "string") {
    return "";
  }
  return str
    .replaceAll("_", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
