import React, { createContext, useState, useContext } from "react";

const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [dialogStates, setDialogStates] = useState({
    uploadCGM: { visible: false, props: {}, callback: null },
    calendar: { visible: false, props: {}, callback: null },
    patientForm: { visible: false, props: {}, callback: null },
    foodForm: { visible: false, props: {}, callback: null },
  });

  const openDialog = (dialogName, props = {}, callback = null) => {
    setDialogStates((prevDialogStates) => ({
      ...prevDialogStates,
      [dialogName]: { visible: true, props, callback },
    }));
  };

  const closeDialog = (dialogName, condition = {}) => {
    console.info("==> closeDialog condition: ", condition);
    setDialogStates((prevDialogStates) => {
      const newDialogStates = { ...prevDialogStates };
      // Check if there's a callback and condition is met
      if (newDialogStates[dialogName].callback && condition.refresh) {
        newDialogStates[dialogName].callback();
      }
      newDialogStates[dialogName] = {
        ...newDialogStates[dialogName],
        visible: false,
        props: {},
      };
      return newDialogStates;
    });
  };

  return (
    <DialogContext.Provider value={{ dialogStates, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};
